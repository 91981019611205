import React, {Component} from 'react'
import {base, appDatabasePrimaryFunctions} from '../../base';
import { Redirect } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { confirmAlert } from '../utils/react-confirm-alert';
import appBackgroundImage from '../../styles/images/background.png';
import sampleAppFrontImage from '../../styles/images/front_icon.png'
import '../../styles/css/ConfirmAlertCustom.css';
import "../../styles/css/modalstyles.css";
import "../../styles/css/appstyles.css";
import "../../styles/css/loginstyles.css";
import {validateEmail, getURLParameter} from "../utils/HelpfulFunction";

class Login extends Component {
    constructor(props) {
        super(props);
        let userEmail;
        try {
          userEmail = localStorage.getItem('userEmail') || false;
        } catch (e) {
          userEmail = false
        }
        this.state = {
          redirect: false,
          tenantVariables: {},
          inputtedEmail: '',
          inputtedName: '',
          inputtedZipCode: '',
          inputtedPhoneNumber: '',
          inputtedFullAddress: '',
          userEmail: userEmail,
          seenCookieError: false
        };
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.toggle = this.toggle.bind(this);
      this.toggleRules = this.toggleRules.bind(this);
    }

    componentDidMount() {
      this.currentGameKeyRef = base.listenTo(`currentGame/id`, {
        context: this,
        then(key){
          let htmlEmailParameter = getURLParameter("email");
          if(htmlEmailParameter && key){
            let base64EncodedEmail = btoa(htmlEmailParameter);
            base.fetch('userGameHistory/'+ base64EncodedEmail+"/"+key, {
              context: this,
              then(data){
                if(typeof data === 'string'){
                  this.props.setPassed(true);
                  this.continueSignInProcess(htmlEmailParameter, "", "", false,"")
                }
              }
            })
          }
        }
      });
      this.props.loadPage();
    }

    toggle() {
      this.setState({
        modal: !this.state.modal
      });
    }

    componentWillUnmount() {
      base.removeBinding(this.currentGameKeyRef);
    }

    isValidDate(s) {
      const bits = s.split('-');
      const d = new Date(bits[0], bits[1] - 1, bits[2]);
      return d && (d.getMonth() + 1) === parseInt(bits[1]);
    }

    sanitizeEmail(emailAddress){
      //get part of email before @ sign, this has different rules then text after the @
      const firstPartOfEmail = emailAddress.split('@')[0];
      //get rest of email to recombine later
      const secondPartOfEmail = emailAddress.split('@')[1];
      //Removes periods because on some mail clients they can be moved around and we will treat them like a seperate email
      const removedPeriodsFirstPartOfEmail = firstPartOfEmail.replace(/\./g, "");
      //Check if there is a plus in the email
      const splitPluses = removedPeriodsFirstPartOfEmail.split('+');
      let finalFirstPart;
      if (splitPluses.length > 1)
      {
        //if there is a plus cut all the nonsense after the plus, this is important because some email clients allow you to put whatever you want after the plus
        finalFirstPart = splitPluses[0];
      }
      else
      {
        //if no plus continue with periods removed from first part of email
        finalFirstPart = removedPeriodsFirstPartOfEmail;
      }
      //recombine email to make the sanitized version
      const sanitizedEmail = finalFirstPart + "@" + secondPartOfEmail;
      //change to upper so people can sneak by case on me
      return sanitizedEmail.toLowerCase();
    }

    handleChange(event) {
      this.setState({[event.target.name]: event.target.value});
    }

    handleDateChange(event) {
    let value = event.target.value;
    if(event.target.name === "month"){
      if(value > 12){
        value = "12"
      }
    } else if(event.target.name === "day"){
      if(value > 31){
        value = "31"
      }
    } else if(event.target.name === "year"){
      if(value > new Date().getFullYear()){
        value = new Date().getFullYear().toString()
      }
    }
    this.setState({[event.target.name]: value});
  }

    handleSubmit(event){
      event.preventDefault();
      const inputtedEmail = this.state.inputtedEmail.trim();
      let htmlParameterEmail = getURLParameter("email");
      const tenantVariables = this.props.variables;
      if(htmlParameterEmail && !validateEmail(htmlParameterEmail)){
        htmlParameterEmail = null
      }

      const inputtedName = this.state.inputtedName.trim();
      const year = this.state.year;
      let month = this.state.month;
      let day = this.state.day;
      let inputtedBirthday = localStorage.getItem('birthday') || "";
      const inputtedZipCode = this.state.inputtedZipCode.trim();
      const inputtedFullAddress = this.state.inputtedFullAddress.trim();
      const inputtedPhoneNumber = this.state.inputtedPhoneNumber.trim();
      const confirmMandatoryRef = this.refs.agree_to_rules_regs.checked;
      const agree_to_promotionRef = this.refs.agree_to_promotion.checked;
      let collectPromoTwo = tenantVariables.collectOptInTwo || false;
      if(collectPromoTwo === "false" || collectPromoTwo === "null" || !collectPromoTwo){
        collectPromoTwo = false
      } else {
        collectPromoTwo = this.refs.agree_to_promotion_two.checked
      }

      if(inputtedEmail === "" && !htmlParameterEmail && !tenantVariables.doNotCollectEmail){
        confirmAlert({
          variables: this.props.variables,
          title: 'Missing Info',
          message: "Please Enter An Email!",
          buttons: [
            {
              label: 'OK',
            }
          ]
        })
        return;
      } else if(!validateEmail(inputtedEmail) && !htmlParameterEmail && !tenantVariables.doNotCollectEmail){
        confirmAlert({
          variables: this.props.variables,
          title: 'Missing Info',
          message: "Unable to read email, please check to make sure it is entered correctly",
          buttons: [
            {
              label: 'OK',
            }
          ]
        })
        return;
      } else if(!tenantVariables.doNotCollectEmail && (tenantVariables.allowList || tenantVariables.blockList)){
        const lowerCaseEmail = inputtedEmail.toLowerCase();
        const emailDomain = lowerCaseEmail.split('@')[1];
        let allowListArray = [];
        let blockListArray = [];
        if(tenantVariables.allowList){
          allowListArray = tenantVariables.allowList.split(" ");
        }
        if(tenantVariables.blockList){
          blockListArray = tenantVariables.blockList.split(" ");
        }
        if(tenantVariables.allowList && allowListArray.indexOf(lowerCaseEmail) === -1 && allowListArray.indexOf(emailDomain) === -1){
          confirmAlert({
            variables: tenantVariables,
            title: 'Email Error',
            message: "This Email Address Not Allowed",
            buttons: [
              {
                label: 'OK',
              }
            ]
          })
          return;
        } else if(tenantVariables.allowList && (allowListArray.indexOf(lowerCaseEmail) !== -1 || allowListArray.indexOf(emailDomain) === -1)) {
          console.log("Allow override")
        } else if(tenantVariables.blockList && (blockListArray.indexOf(lowerCaseEmail) !== -1 || blockListArray.indexOf(emailDomain) !== -1)){
          confirmAlert({
            variables: tenantVariables,
            title: 'Email Error',
            message: "This Email Address Not Allowed",
            buttons: [
              {
                label: 'OK',
              }
            ]
          })
          return;
        }
      }

      const allowedAge = tenantVariables.allowedAge || 21;
      if(tenantVariables.collectBirthday && tenantVariables.formBirthday && (!year || !month || !day)){
        confirmAlert({
          variables: tenantVariables,
          title: 'Missing Info',
          message: "Please Enter A Birthday!",
          buttons: [
            {
              label: 'OK',
            }
          ]
        })
        return;
      } else if(tenantVariables.collectBirthday && tenantVariables.formBirthday){
        if(year.length < 4){
          confirmAlert({
            variables: tenantVariables,
            title: 'Missing Info',
            message: "Please make sure the year is 4 digits!",
            buttons: [
              {
                label: 'OK',
              }
            ]
          })
          return;
        }
        if(month && month.length < 2){
          month = "0" + month
        }
        if(day && day.length < 2){
          day = "0" + day
        }
        inputtedBirthday = year + "-" + month + "-" + day;
        if(isNaN(new Date(inputtedBirthday).getTime()) || !this.isValidDate(inputtedBirthday)){
          confirmAlert({
            variables: tenantVariables,
            title: 'Missing Info',
            message: "Invalid date entered, make sure the date exists",
            buttons: [
              {
                label: 'OK',
              }
            ]
          })
          return;
        }
        const age = ~~((new Date().getTime() - +new Date(inputtedBirthday)) / (31557600000))
        if (age < allowedAge) {
          confirmAlert({
            variables: tenantVariables,
            title: 'Input Error',
            message: "Your are not old enough to participate",
            buttons: [
              {
                label: 'OK',
              }
            ]
          })
          return;
        }
      }

      if(tenantVariables.collectName && inputtedName === ""){
        confirmAlert({
          variables: this.props.variables,
          title: 'Missing Info',
          message: "Please Enter A Name!",
          buttons: [
            {
              label: 'OK',
            }
          ]
        })
        return;
      } else if(tenantVariables.collectName && !tenantVariables.noLastNameCheck && (inputtedName === "" || inputtedName.split(' ').length <= 1)){
        confirmAlert({
          variables: tenantVariables,
          title: 'Input Error',
          message: "Must include a last name!",
          buttons: [
            {
              label: 'OK',
            }
          ]
        })
        return;
      }

      if(tenantVariables.collectPhoneNumber && inputtedPhoneNumber === ""){
        if(!tenantVariables.optionalPhoneNumber){
          confirmAlert({
            variables: tenantVariables,
            title: "Missing Info",
            message: "Please enter a phone number",
            buttons: [
              {
                label: "OK",
              }
            ]
          })
          return;
        }
      } else if(tenantVariables.collectPhoneNumber && !/^\d+$/.test(inputtedPhoneNumber)){
        confirmAlert({
          variables: tenantVariables,
          title: "Missing Info",
          message: "Please enter a valid phone number that is only numbers and exactly 10 digits long",
          buttons: [
            {
              label: "OK",
            }
          ]
        })
        return;
      } else if(tenantVariables.collectPhoneNumber && inputtedPhoneNumber.length !== 10){
        confirmAlert({
          variables: tenantVariables,
          title: "Missing Info",
          message: "Please enter a valid phone number that is only numbers and exactly 10 digits long",
          buttons: [
            {
              label: "OK",
            }
          ]
        })
        return;
      }

      if(tenantVariables.collectZipCode && inputtedZipCode === ""){
        const isAlberta = process.env.REACT_APP_FIREBASE_PROJECT_ID === "albertaballetsh";
        let zipCodeErrorMessage = 'Please Enter A Zip Code!';
        if(isAlberta){
          zipCodeErrorMessage = "Please Enter A Postal Code!"
        }
        confirmAlert({
          variables: tenantVariables,
          title: 'Missing Info',
          message: zipCodeErrorMessage,
          buttons: [
            {
              label: 'OK',
            }
          ]
        })
        return;
      } else if(tenantVariables.collectZipCode && !tenantVariables.doNotVerifyAmericanZipCode && !/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(inputtedZipCode)){
        confirmAlert({
          variables: tenantVariables,
          title: 'Missing Info',
          message: "Please enter a valid 5-digit U.S. Zip Code (Ex. 83684)",
          buttons: [
            {
              label: 'OK',
            }
          ]
        })
        return;
      }

      if(tenantVariables.collectFullAddress && inputtedFullAddress === ""){
        confirmAlert({
          variables: tenantVariables,
          title: 'Missing Info',
          message: 'Please enter a full address.',
          buttons: [
            {
              label: 'OK',
            }
          ]
        })
        return;
      }

      if(!tenantVariables.noMandatoryTermsAndConditions && !confirmMandatoryRef){
        confirmAlert({
          variables: tenantVariables,
          title: 'Missing Info',
          message: "Please agree to rules and regulations!",
          buttons: [
            {
              label: 'OK',
            }
          ]
        })
        return;
      }
      // Play with ' + this.state.inputedEmail + '?'
      if(tenantVariables.doNotCollectEmail){
        this.continueSignInProcess(inputtedEmail, inputtedZipCode, inputtedName, agree_to_promotionRef, inputtedBirthday, inputtedPhoneNumber, inputtedFullAddress, collectPromoTwo)
        return
      }
      if(!htmlParameterEmail) {
        confirmAlert({
          variables: tenantVariables,
          title: "Confirm Email",
          message: inputtedEmail,
          buttons: [
            {
              label: "NO",
            },
            {
              label: "YES",
              onClick: async () => {
                this.setState({
                  loading:true
                })
                this.continueSignInProcess(inputtedEmail, inputtedZipCode, inputtedName, agree_to_promotionRef, inputtedBirthday, inputtedPhoneNumber, inputtedFullAddress, collectPromoTwo)
              }
            }
          ]
        })
      } else {
        this.props.setPassed(true);
        this.continueSignInProcess(htmlParameterEmail, inputtedZipCode, inputtedName, agree_to_promotionRef, inputtedBirthday, inputtedPhoneNumber, inputtedFullAddress, collectPromoTwo);
      }
    }

    continueSignInProcess(inputtedEmail, inputtedZipCode, inputtedName, agree_to_promotionRef, inputtedBirthday, inputtedPhoneNumber, inputtedFullAddress, collectPromoTwo){
      let base64EncodedEmail = appDatabasePrimaryFunctions.ref().push().key;
      let userId = getURLParameter("userid");
      const tenantVariables = this.props.variables || {};
      let sanitizedEmail = "";
      if(inputtedEmail){
        try {
          sanitizedEmail = this.sanitizeEmail(inputtedEmail);
        } catch (e) {
          console.log(e)
        }
        base64EncodedEmail = btoa(inputtedEmail);
      } else if(userId){
        base64EncodedEmail = userId;
      }
      const userObject = {};
      userObject['lastSignIn'] = new Date().getTime();
      userObject['email'] = inputtedEmail;
      userObject['sanitizedEmail'] = sanitizedEmail;
      userObject['uid'] = base64EncodedEmail;
      if(inputtedZipCode){
        userObject['zipCode'] = inputtedZipCode;
      }
      if(inputtedName){
        userObject['name'] = inputtedName;
      }
      if(inputtedFullAddress){
        userObject['address'] = inputtedFullAddress;
      }
      userObject['optIn'] = agree_to_promotionRef;
      userObject['optInTwo'] = collectPromoTwo || false;
      if(inputtedBirthday){
        userObject['birthday'] = inputtedBirthday;
      }
      if(inputtedPhoneNumber){
        userObject['phoneNumber'] = inputtedPhoneNumber;
      }
      const vm = this;
      if(tenantVariables.sanitizeEmails && sanitizedEmail && inputtedEmail){
        inputtedEmail = sanitizedEmail;
        base64EncodedEmail = btoa(sanitizedEmail)
        userObject['uid'] = base64EncodedEmail;
      }
      base.update('users/' + base64EncodedEmail, {
        data: userObject,
        context: this,
        then(err){
          if(!err){
            try {
              if(inputtedEmail){
                localStorage.setItem('userEmail', inputtedEmail);
              } else {
                localStorage.setItem('userEmail', base64EncodedEmail);
              }
              vm.props.setCurrentUser();
              vm.setState({redirect: true})
            } catch(e){
              console.log(e)
              alert("Unable to log you in! Check your settings and try again!")
            }
          } else {
            alert("Oh No! There was an error please try again!")
          }
        }
      })
      if(base64EncodedEmail){
        appDatabasePrimaryFunctions.ref('users/' + base64EncodedEmail + '/signUpTime').set(new Date().getTime())
            .catch(function(error){
              console.log(error)
            })
      }
    }

    toggleRules() {
      this.setState({
        modalRules: !this.state.modalRules,
      });
    }

    handleBirthdayFocus(){
        this.setState({
          birthdayFocused: true
        }, ()=> {
          document.getElementById('year').placeholder ="YYYY"
          document.getElementById('day').placeholder = "DD"
          document.getElementById('month').placeholder = "MM"
        })
    }

    render() {
      const { redirect } = this.state;
      const tenantVariables = this.props.variables || {};
      let htmlParameterEmail = getURLParameter("email");
      if(htmlParameterEmail && !validateEmail(htmlParameterEmail)){
        htmlParameterEmail = null
      }
      const isRsl = process.env.REACT_APP_FIREBASE_PROJECT_ID === "rslfanscan" || process.env.REACT_APP_FIREBASE_PROJECT_ID === "sufanscan";
      const isAlberta = process.env.REACT_APP_FIREBASE_PROJECT_ID === "albertaballetsh";
      const tenantRules = this.props.tenantRulesVariables || {};
      const link = tenantRules.rulesAndRegsLink;
      const rulesRegsText = tenantRules.rulesAndRegsText || "I agree to the rules and regs";
      const howToPlayLink = tenantRules.howToPlayLink || "";
      let howToPlayText = tenantRules.howToPlayText || "";
      const promotionText = tenantVariables.promotionText || "Check To Opt-In To More Offers From Us";
      const verifiedAge = this.props.checkForAgeGate(tenantVariables);
      if(!verifiedAge){
        return (
            <Redirect to="/age_gate" />
        )
      }
      if (redirect || this.state.userEmail) {
        this.props.setCurrentUser();
          return (
              <Redirect to="/" />
          )
      }
      const backgroundImage = tenantVariables.backgroundImage || appBackgroundImage;
      const frontLogoImage = tenantVariables.frontLogoImage || sampleAppFrontImage;
      const primaryColor = tenantVariables.primaryColor || "black";
      const secondaryColor = tenantVariables.secondaryColor || "white";
      const rulesPopUpHeader = tenantRules.rulesPopUpHeader || "Rules & Regs";
      const rulesPopUpText = tenantRules.rulesPopUpText || "";
      let showHowToPlayButton = false;
      if(howToPlayText === "<p><br></p>"){
        howToPlayText = "";
      }
      if(howToPlayText || howToPlayLink){
        showHowToPlayButton = true
      }

      return(
          <div className="flex-container-home" style={{backgroundImage: "url(" + backgroundImage + ")"}}>
            <div className="flex-content-container-home">
              <div className="intro-container-home">
                {isRsl &&
                    <>
                      ©2023 KeyCorp. KeyBank Member FDIC
                    </>
                }
                <div className="hero-text-container" style={{paddingTop:0}}>
                  <img src={frontLogoImage} className="main-hero-image" style={{maxWidth: "100%"}} alt=""/>
                </div>
                <div className="container-out">
                  <div className="question-box question-form" style={{padding:'15px'}}>
                    <h4 style={{fontFamily: "Oswald", fontWeight: 700, display: htmlParameterEmail || tenantVariables.doNotCollectEmail ? "none":""}}>Enter your email to play</h4>
                    {/*<p className="text-muted" style={{fontFamily: "Oswald"}}>So we know where to send your prize</p>*/}
                    <form onSubmit={this.handleSubmit} id="user-values-form">
                      <div className="input-group" style={{display: tenantVariables.doNotCollectEmail || htmlParameterEmail ? 'none' : ""}}>
                        <input id="email" name="inputtedEmail" type="email" style={{fontFamily: "Oswald", fontWeight: 700}} className="form-control" onChange={this.handleChange} placeholder="Email" />
                      </div>
                      <div className="input-group" style={{display: tenantVariables.collectPhoneNumber ? '' : "none"}}>
                        <div style={{width:"100%", height:10}}/>
                        <input id="phoneNumber" name="inputtedPhoneNumber" type="tel" style={{fontFamily: "Oswald", fontWeight: 700, borderTopLeftRadius: "0.25rem", borderBottomLeftRadius:"0.25rem"}} className="form-control" onChange={this.handleChange} placeholder="Phone Number" />
                      </div>
                      <div className="input-group" style={{display: tenantVariables.collectName ? '' : "none", marginTop: 10}}>
                        <input id="name" name="inputtedName" type="text" style={{fontFamily: "Oswald", fontWeight: 700}} className="form-control" onChange={this.handleChange} placeholder="Name" />
                      </div>
                      <div className="input-group" style={{display: tenantVariables.collectZipCode ? '' : "none", marginTop: 10}}>
                        <input id="zipCode" name="inputtedZipCode" type="text" style={{fontFamily: "Oswald", fontWeight: 700}} className="form-control" onChange={this.handleChange} placeholder={isAlberta? "Postal Code" : "Zip Code"} />
                      </div>
                      <div className="input-group" style={{display: tenantVariables.collectFullAddress ? '' : "none"}}>
                        <div style={{width:"100%", height:10}}/>
                        <input id="fullAddress" name="inputtedFullAddress" type="text" style={{fontFamily: "Oswald", fontWeight: 700, borderTopLeftRadius: "0.25rem", borderBottomLeftRadius:"0.25rem"}} className="form-control" onChange={this.handleChange} placeholder="Mailing Address" />
                      </div>
                      <div className="input-group" style={{display: tenantVariables.collectBirthday && tenantVariables.formBirthday ? '' : 'none', border: "1px solid #ced4da", borderRadius: "0.25rem", marginTop: 10}}>
                        <input value={this.state.month} onKeyUp={(e)=>e.target.value && e.target.value.length === 2 && document.getElementById("day").focus()} name="month" id="month" onChange={(e)=> this.handleDateChange(e)} className="form-control" type="text" style={{border:"none",fontFamily: "Oswald"}} onFocus={(e) => this.handleBirthdayFocus()} placeholder="Birthday" maxLength="2" pattern="\d*"/>
                        {this.state.birthdayFocused &&
                            <>
                              <span style={{display: this.state.year || this.state.day || this.state.month?"":"none", alignSelf:"center"}}>/</span>
                              <input value={this.state.day} onKeyUp={(e)=>e.target.value && e.target.value.length === 2 && document.getElementById("year").focus()} name="day" id="day" className="form-control" type="text" style={{border:"none",fontFamily: "Oswald"}} onChange={(e)=> this.handleDateChange(e)} onFocus={(e) => this.handleBirthdayFocus()} maxLength="2" pattern="\d*"/>
                              <span style={{display: this.state.year || this.state.day || this.state.month?"":"none", alignSelf:"center"}}>/</span>
                              <input value={this.state.year} name="year" id="year" className="form-control" type="text" style={{border:"none",fontFamily: "Oswald"}} onFocus={(e) => this.handleBirthdayFocus()} maxLength="4" pattern="\d*" onChange={(e)=> this.handleDateChange(e)}/>
                            </>
                        }
                      </div>
                      <div style={{display:!tenantVariables.noMandatoryTermsAndConditions?'table-row':"none",textAlign:'left'}}>
                        <div style={{display: "table-cell", verticalAlign:'middle',padding:15}}>
                          <input type="checkbox" ref="agree_to_rules_regs" style={{width:20,height:20}}/>
                        </div>
                        <div style={{display: "table-cell", verticalAlign:'middle', fontFamily: "Oswald"}}>
                          <label htmlFor="agreeRulesAndRegsId"><strong><u><span onClick={()=>{link?window.open(link, '_blank'):this.toggleRules()}}>{rulesRegsText}</span></u></strong></label>
                        </div>
                      </div>
                      <div style={{display:tenantVariables.collectOptIn?'table-row':"none",textAlign:'left'}}>
                        <div style={{display: "table-cell", verticalAlign:'middle',padding:"0 15px 0 15px"}}>
                          <input type="checkbox" ref="agree_to_promotion" defaultChecked={!tenantVariables.notDefaultCheckedOptIn && tenantVariables.collectOptIn} style={{width:20,height:20}}/>
                        </div>
                        <div style={{display: "table-cell", verticalAlign:'middle', fontFamily: "Oswald"}}>
                          {/*<label htmlFor="agreeToPromotionId">{promotionText}</label>*/}
                          <label id="agreeToPromotionId" htmlFor="agreeToPromotionId" dangerouslySetInnerHTML={{__html: promotionText}}/>
                        </div>
                      </div>
                      <div style={{display:tenantVariables.collectOptInTwo?'table-row':"none",textAlign:'left'}}>
                        <div style={{display: "table-cell", verticalAlign:'middle',padding:"0 15px 0 15px"}}>
                          <input type="checkbox" ref="agree_to_promotion_two" defaultChecked={!tenantVariables.collectOptInTwoNotDefaultCheck && tenantVariables.collectOptInTwo} style={{width:20,height:20}}/>
                        </div>
                        <div style={{display: "table-cell", verticalAlign:'middle', fontFamily: "Oswald"}}>
                          <label id="agreeToPromotionIdTwo" htmlFor="agreeToPromotionIdTwo" dangerouslySetInnerHTML={{__html: tenantVariables.promotionTextTwo}}/>
                        </div>
                      </div>
                      <button style={{backgroundColor: primaryColor, color: secondaryColor}} className="btn btn-play" id="submitButton"><strong>LET'S PLAY!</strong></button>
                      {isRsl &&
                        <div className="input-group" style={{marginTop:10}}>
                          MANY WILL ENTER THE KEYBANK FANSCAN SWEEPSTAKES DURING SWEEPSTAKES PERIOD, FEW WILL WIN. NO PURCHASE OR PAYMENT NECESSARY TO ENTER. A PURCHASE OF ANY KIND WILL NOT INCREASE YOUR CHANCES OF WINNING. VOID OUTSIDE NEW YORK AND WHERE PROHIBITED. OPEN ONLY TO LEGAL U.S. RESIDENTS, RESIDING IN NEW YORK, 18 YEARS OF AGE AND OLDER. BEGINS 1/14/23 AT 12:00AM ET AND ENDS 2/28/23 AT 11:59PM ET. SEE OFFICIAL RULES FOR DETAILS.
                        </div>
                      }
                    </form>
                    <button style={{display: showHowToPlayButton?"":"none", backgroundColor: primaryColor, color: secondaryColor}} className="btn btn-rules" onClick={()=>{howToPlayText? this.toggle():window.open(howToPlayLink, '_blank');}}><strong>How To Play</strong></button>
                  </div>
                </div>
              </div>
            </div>
            <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
              <ModalHeader/>
              <ModalBody style={{textAlign:"center"}}>
                <div className="container-out" style={{paddingLeft: 20, paddingRight: 20}}>
                  <span dangerouslySetInnerHTML={{ __html:howToPlayText}}/>
                  <button className="btn btn-play" onClick={() => { this.toggle(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem'}}>OK</button>
                </div>
              </ModalBody>
              <ModalFooter style={{borderTop:'none'}}/>
            </Modal>
            <Modal isOpen={this.state.modalRules} style={{width: '90%'}} id="rulesModal">
              <ModalHeader style={{color: secondaryColor, padding:10}}>
                <span style={{fontSize:25}}>
                  {rulesPopUpHeader}
                </span>
              </ModalHeader>
              <ModalBody>
                <center className="container-out">
                  <div className="question-box question-form">
                    <p style={{width:"100%",height:200, overflow:"scroll",padding:10, borderBottom:"1px solid black", borderTop: "1px solid black", fontWeight:100, fontSize:14}} dangerouslySetInnerHTML={{ __html:rulesPopUpText}}/>
                    <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleRules(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem'}}>Dismiss</button>
                  </div>
                </center>
              </ModalBody>
            </Modal>
          </div>
      )
    }
}

export default Login;
